<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "SubmissionPeriodUpdate",
  data() {
    return {
      submissionPeriod: {
        start_date: null,
        end_date: null,
      },
      errorMsg: null,
    };
  },
  mounted() {
    this.getSubmissionPeriod();
  },
  methods: {
    getSubmissionPeriod() {
      axios.get("submission_period").then((res) => {
        const startDate = new Date(res.data.submission_period.start_date);
        const endDate = new Date(res.data.submission_period.end_date);
        this.submissionPeriod.start_date = startDate;
        this.submissionPeriod.end_date = endDate;
      });
    },
    updateSubmissionPeriod() {
      if (this.submissionPeriod.start_date > this.submissionPeriod.end_date) {
        this.errorMsg =
          "Дата начала подачи заявлений не может быть позже даты окончания!";
        return;
      }

      const data = {
        start_date: moment(this.submissionPeriod.start_date).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(this.submissionPeriod.end_date).format("YYYY-MM-DD"),
      };

      axios
        .put("submission_period", data)
        .then(() => {
          this.$buefy.toast.open({
            message: "Период подачи заявлений обновлен",
            type: "is-success",
            queue: false,
            position: "is-top-right",
            duration: 2500,
          });
          this.$router.push({
            name: "Home",
          });
        })
        .catch((error) => {
          this.errorMsg = error.response.data.message;
        });
    },
  },
};
</script>

<template>
  <div class="columns is-centered">
    <div class="column is-4">
      <b-field label="Дата начала подачи заявлений">
        <b-datepicker
          v-model="submissionPeriod.start_date"
          locale="ru"
          placeholder="Нажмите, чтобы выбрать дату..."
          icon="calendar-today"
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field label="Дата окончания подачи заявлений">
        <b-datepicker
          v-model="submissionPeriod.end_date"
          locale="ru"
          placeholder="Нажмите, чтобы выбрать дату..."
          icon="calendar-today"
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field class="has-text-centered">
        <b-button class="is-primary" @click="updateSubmissionPeriod" expanded
          >Сохранить</b-button
        >
        <p v-if="errorMsg" class="has-text-danger mt-3">{{ errorMsg }}</p>
      </b-field>
    </div>
  </div>
</template>
